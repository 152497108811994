body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
  top: 2em;
}

.form-switch .form-check-input {
  width: 3em; height: 1.5em;
}
